import axios from "../../../services/Api";
import config from "../../../../public/config.json"

export default {
  methods: {
    async authenticate() {
      window.addEventListener('message', async function(event) {
        if (event?.data?.type === 'omniwallet_auth') {
          localStorage.setItem("omniwalletTemporaryToken", event.data.data)
          this.signIn();
        }
      }.bind(this));
    },
    async fireAuthReadyEvent() {
      window.parent.postMessage({
        type: "omniwallet_auth_ready",
      }, '*');
    },
    async authenticateWithQueryParam() {
      let authParam = window.location.hash;
      authParam = authParam.replace('#/auth','');
      authParam = authParam.replace('?','');

      if (authParam.startsWith("t=")) {
        authParam = authParam.replace('t=','');
        localStorage.setItem("omniwalletTemporaryToken", authParam)
        this.signIn();
      }
    }
  },
};
